.AdminStatusActive {
  background-color: darkolivegreen;
  color: lightyellow;
}

.AdminStatusFinished {
  color: #282c34
}

.AdminStatusFuture {
  background-color: coral;
  color: #282c34
}

.FilterRow {
  font-size: 90%;
  padding: 15px;
  border: darkgray 1px solid;
  text-align: left;
}

.FilterUpdateCol {
  display: flex;
  align-items: center;
}

.FilterUpdateBtn {
  flex-grow: 1;
  margin-top: 12px;
}

.ScrutinsRow {
  font-size: 80%;
}

.ScrutinsRowConfirm {
  background-color: red;
  color: white;
}

.DelButton {
  background-color: red;
  color: white;
}

.RowMargins {
  margin-top: 8px;
  margin-bottom: 8px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.AuthDisconnect {
  width: 100%;
  padding: 8px;
  text-align: right;
}

.AuthDialog {
  padding: 10px;
  text-align: left;
  max-width: 300px;
  margin: 5px;
  border: 2px solid rgb(0, 105, 217);
  border-radius: 5px;
}

.AuthContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ScrutinsErr {
  color: red;
}